<template>
  <modal @close="closeClicked" :show="show" :hideClose="false">
    <h1 class="tutorial__title mx-auto max-content">{{ title }} Tutorial</h1>
    <span
      v-if="!videoReady"
      class="tutorial__spinner spinner-border text-primary"
      role="status"
    />

    <div class="tutorial__video-container">
      <youtube
        width="100%"
        height="100%"
        class="position-absolute top-0"
        :video-id="videoId"
        ref="youtube"
        @ready="videoLoaded"
        @buffering="videoLoaded"
        :player-vars="playerVars"
      >
      </youtube>
    </div>
  </modal>
</template>

<script>
import { getIdFromUrl } from "vue-youtube";
import Modal from "@/components/Modal";

export default {
  name: "TutorialPopUp",
  components: { Modal },
  props: ["title", "show", "videoUrl"],
  data() {
    return {
      videoReady: false,
      playerVars: {
        autoplay: 1,
      },
    };
  },
  methods: {
    closeClicked() {
      this.$emit("close");
    },
    videoLoaded() {
      this.videoReady = true;
    },
  },
  computed: {
    videoId() {
      return getIdFromUrl(this.videoUrl);
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active {
  transition: opacity 100ms linear;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.tutorial {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  width: 100%;

  &__body {
    opacity: 1;
    width: 100%;
    padding: 2rem;
    background: var(--grey-3);
    height: 100%;
    overflow-y: auto;
    border-radius: 0.5rem;
    position: relative;
  }

  &__title {
    color: var(--neutral-light);
    font-size: 3rem;
    margin-bottom: 5rem;
  }

  &__video-container {
    width: 100%;
    display: block;
    position: relative;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 768px) {
      width: 60%;
    }

    &::before {
      content: "";
      width: 100%;
      display: block;
      padding-bottom: 56.25%;
    }
  }

  &__spinner {
    width: 10rem;
    height: 10rem;
    position: absolute;
    top: 30%;
    left: calc(50% - 5rem);
    border-width: 1rem;
    display: block;
  }
}
</style>
