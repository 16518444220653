<template>
  <section
    class="d-flex align-items-center justify-content-between parent mx-3"
    v-if="total > 1"
  >
    <div v-for="i in total" :key="i" class="d-flex align-items-center w-100">
      <div :class="['dot', current <= i - 2 ? '' : 'dot--active']" />
      <hr
        v-if="i < total"
        :class="['line', current <= i - 1 ? '' : 'line--active']"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "Stepper",
  props: {
    total: Number,
    current: Number,
  },
};
</script>

<style lang="scss" scoped>
$size: 20px;
$line: 5px;

.parent {
  /* max-width: 350px; */
  flex-grow: 1;
  flex-basis: 0;
}

.dot {
  height: $size;
  width: $size;
  display: inline-block;

  background-color: gray;
  border-radius: 50%;
  &--active {
    background-color: var(--blue-1);
  }
}

.line {
  height: $line;
  display: inline-block;
  flex-grow: 1;

  background-color: gray;
  &--active {
    background-color: var(--blue-1);
  }
}
</style>
