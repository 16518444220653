<template>
  <div class="cart-loading d-flex justify-content-center align-items-center">
    <video
      class="cart-loading__video wd-50p hd-50p"
      :src="require('../assets/cart-loading.mp4')"
      autoplay
      muted
      loop
    />
  </div>
</template>

<script>
const LOADING_TIME = 5000;

export default {
  name: "CartLoading",
  mounted() {
    this.$el.style["height"] = `${window.innerHeight}px`;
    this.$el.style["width"] = `${window.innerWidth}px`;

    setTimeout(() => {
      this.$emit("loading-done");
    }, LOADING_TIME);
  },
};
</script>

<style scoped lang="scss">
.cart-loading {
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;
  background: #002c80;

  &__video {
    object-fit: contain;
  }
}
</style>
