<template>
  <div class="select-container">
    <square-container
      :key="option.id"
      v-for="option in options"
      class="orderedFade"
    >
      <div
        @click="$emit('input', option.id)"
        :class="[
          'p-2 cursor-pointer rounded-borders-8 select-box flex-column h-100',
          value === option.id ? 'select-box--selected' : '',
          width,
        ]"
      >
        <img
          class="wd-40p"
          :alt="option.preview"
          :src="option.image_url ? option.image_url : option.image"
        />
        <div class="text-light text-center subtitle-text font-weight-500 mt-3">
          {{ option.text }}
        </div>
      </div>
    </square-container>
  </div>
</template>

<script>
import SquareContainer from "@/components/SquareContainer";
export default {
  name: "SelectQuestion",
  components: { SquareContainer },
  props: {
    options: Array,
    value: Number,
    width: { type: String, default: "col-2" },
  },
};
</script>

<style lang="scss" scoped>
@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  100% {
    transform: scale(1);
  }
}

.orderedFade {
  animation: FadeIn 0.5s linear;
  animation-fill-mode: both;
}

@for $i from 1 through 10 {
  .orderedFade:nth-child(#{$i}n) {
    animation-delay: #{$i * 0.25}s;
  }
}

.select-box {
  padding: 10px;
  width: 100%;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px white;
  &--selected {
    border: solid 2px white;
    background-color: var(--blue-1);
  }
}

.select-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1rem;
}
</style>
