<template>
  <v-select
    class="room-picker"
    :items="rooms"
    item-text="name"
    item-value="id"
    :value="currentRoom"
    @input="switchRooms"
    solo
    hide-details
    background-color="var(--blue-4)"
  />
</template>

<script>
export default {
  name: "RoomPicker",
  methods: {
    switchRooms(room) {
      this.$router.push({ name: "Room", params: { id: room } });
    },
  },
  computed: {
    rooms: function () {
      return this.$store.state.dashboard.rooms.filter(
        (r) => r.name !== "Project"
      );
    },
    currentRoom: function () {
      return this.$store.state.dashboard.rooms.filter(
        (r) => r.name !== "Project"
      )[parseInt(this.$route.params.id)];
    },
  },
};
</script>

<style lang="scss">
.room-picker {
  .v-select__selection {
    font-size: 2rem;
    color: var(--primary);
    line-height: 1.5;
  }

  .v-input__slot {
    border: 1px solid var(--neutral-light) !important;
    border-radius: 10px !important;
  }

  .v-icon {
    color: var(--primary);
    font-size: 3rem;
    &::before {
      content: "\F0140";
    }
  }
}
</style>
