<template>
  <main
    class="p-4 p-md-5 pb-0 pb-md-0 pt-3 pt-md-3 bg-neutral-dark d-flex justify-content-between flex-column"
  >
    <tutorial-pop-up
      v-if="systemTutorial && systemTutorial.tutorialUrl"
      :title="systemTutorial.title"
      @close="tutorialClosed"
      :show="showTutorial"
      :video-url="systemTutorial.tutorialUrl"
    />
    <transition mode="out-in" :name="`slide-${transitionName}`">
      <div :key="roomId">
        <div
          class="mt-2 mb-3 d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center flex-wrap">
            <span
              class="text-capitalize text-neutral-light strong-header-text mr-2"
            >
              {{ $store.state.project.name || "Project Name" }}
            </span>
            <v-icon class="d-none d-md-inline" color="#fff" size="30px">
              mdi-chevron-right
            </v-icon>
            <span
              class="d-none d-md-inline strong-header-text font-weight-300 text-primary-light"
            >
              {{ room.name || "Project Name" }}
            </span>
          </div>

          <div class="d-none d-md-flex align-items-center">
            <div
              class="rounded-button bg-neutral-light px-3 mx-2"
              @click="$store.commit('dashboard/copy', roomId)"
            >
              <v-icon color="#000" size="18px"> mdi-content-copy </v-icon>
              <span class="subtitle-text font-weight-500 ms-1"
                >Copy Room Settings</span
              >
            </div>

            <div
              class="rounded-button bg-dark-grey px-3 mx-2 text-neutral-dark"
              :class="{
                'bg-neutral-light': room.systems
                  .map((s) => s.questions)
                  .flat()
                  .some((q) => q.answer_id),
              }"
              @click="$store.dispatch('dashboard/resetRoom', roomId)"
            >
              <v-icon color="#000" size="18px"> mdi-restart </v-icon>
              <span class="subtitle-text font-weight-500 ms-1"
                >Reset Room Settings</span
              >
            </div>

            <div
              class="rounded-button bg-dark-grey px-3 mx-2 text-neutral-dark"
              :class="{ 'bg-neutral-light': $store.state.dashboard.clipboard }"
              @click="$store.commit('dashboard/apply', roomId)"
            >
              <v-icon color="#000" size="18px"> mdi-content-save-edit </v-icon>
              <span class="subtitle-text font-weight-500 ms-1"
                >Apply Room Settings</span
              >
            </div>
          </div>
        </div>
        <room-picker class="d-block d-md-none" />

        <section
          class="mt-3 top-bar bg-blue-3 d-flex justify-content-around align-items-center mb-3"
        >
          <div
            class="top-bar__box d-flex flex-column justify-content-around align-items-center"
            v-for="(system, index) in room.systems.filter(
              (s) => s.name !== 'Home'
            )"
            :key="system.id"
            @click="setCurrentSystem(index)"
          >
            <div
              class="w-100 d-flex flex-column justify-content-around align-items-center"
            >
              <img
                :class="['icon', currentSystem === index ? '' : 'disable']"
                :src="require(`../assets/icons/Dashboard/${system.icon}.png`)"
              />
              <span
                :class="[
                  'd-none d-md-block body-text text-center font-weight-500  mt-2 system-title text-white',
                  currentSystem === index ? '' : 'disable',
                ]"
              >
                {{ system.name }}</span
              >
            </div>
          </div>
        </section>
        <transition mode="out-in" :name="`slide-${transitionName}`">
          <div
            :key="currentSystemQuestions[0].id"
            class="d-flex w-100 gap-5 h-100 align-items-start mt-5"
          >
            <div class="d-flex flex-column wd-65p z-200">
              <img
                :src="require('../assets/images/compound-bg.jpeg')"
                style="
                  object-fit: contain;
                  border-radius: 1rem;
                  border: 1px solid #fff;
                "
                class="w-100"
              />
              <stepper
                v-if="currentSystemQuestions.length > 0"
                :total="currentSystemQuestions.length"
                :current="currentQuestionId"
                class="mt-3 ms-0 w-100"
              />
            </div>

            <transition mode="out-in" :name="`slide-${transitionName}`">
              <section
                class="d-flex flex-column flex-md-row flex-wrap w-100 justify-content-center align-content-center gap-5"
                :key="currentSystemQuestion"
              >
                <room-question
                  :question="currentSystemQuestion"
                  @rating-clicked="
                    (questionId, OptionId) =>
                      ratingClicked(questionId, OptionId)
                  "
                />
              </section>
            </transition>
          </div>
        </transition>
      </div>
    </transition>

    <section
      class="navigation-border bg-neutral-dark py-3 mt-5 position-sticky bottom-0"
    >
      <div class="d-flex justify-content-between">
        <button
          @click="prev"
          :class="[
            'border-neutral-light nav-button subtitle-text text-neutral d-flex align-items-center justify-content-center bg-black font-weight-300',
          ]"
        >
          <v-icon color="#fff" size="20px" class="me-1">
            mdi-chevron-left
          </v-icon>
          {{ back }}
        </button>

        <button
          @click="nextPressed"
          class="nav-button text-uppercase subtitle-text text-neutral d-flex align-items-center justify-content-center bg-blue-2 font-weight-300"
        >
          {{ next }}
          <v-icon color="#fff" size="20px" class="me-1">
            mdi-chevron-right
          </v-icon>
        </button>
      </div>
    </section>
    <cart-loading v-if="showLoading" @loading-done="cartLoadingDone" />
  </main>
</template>

<script>
import RoomPicker from "../components/RoomPicker.vue";
import TutorialPopUp from "@/components/TutorialPopUp";
import RoomQuestion from "../components/RoomQuestion.vue";
import Stepper from "../components/Stepper.vue";
import CartLoading from "@/components/CartLoading";

export default {
  name: "Room",
  components: { CartLoading, TutorialPopUp, RoomPicker, RoomQuestion, Stepper },
  data() {
    return {
      currentSystem: 0,
      currentQuestionId: 0,
      transitionName: "left",
      showLoading: false,
    };
  },
  methods: {
    cartLoadingDone() {
      this.$store.dispatch("dashboard/submit");
    },
    setCurrentSystem(index) {
      this.currentSystem = index;
      this.currentQuestionId = 0;
    },
    nextPressed() {
      this.transitionName = "left";

      if (this.next === "Go To Cart") {
        this.showLoading = true;
      } else if (this.next === "Next Room") {
        let currentRoomIndex = this.rooms.findIndex(
          (r) => r.id === this.roomId
        );

        this.$router.replace({
          name: "Room",
          params: { id: this.rooms[currentRoomIndex + 1].id },
        });
      } else if (this.next === "Next System") {
        this.currentSystem++;
        this.currentQuestionId = 0;
      } else {
        this.currentQuestionId++;
      }
    },
    prev() {
      this.transitionName = "right";

      if (this.currentQuestionId === 0 && this.currentSystem === 0) {
        this.$router.replace({ name: "dashboard" });
      } else if (this.currentQuestionId === 0) {
        this.currentSystem--;
        this.currentQuestionId = this.currentSystemQuestions.length - 1;
      } else {
        this.currentQuestionId--;
      }
    },
    ratingClicked(questionId, optionId) {
      this.$store.commit("dashboard/setRoomAnswer", {
        questionId: questionId,
        systemIndex: this.currentSystem,
        answerId: optionId,
        roomId: this.room.id,
      });
    },
    tutorialClosed() {
      this.$store.commit(
        "dashboard/setTutorialShown",
        this.systemTutorial?.category
      );
    },
  },
  computed: {
    currentSystemQuestions() {
      return this.room?.systems[this.currentSystem]?.questions || [];
    },
    currentSystemQuestion() {
      return this.currentSystemQuestions[this.currentQuestionId];
    },
    room() {
      return this.$store.getters["dashboard/getRoomById"](this.roomId);
    },
    roomId: function () {
      return parseInt(this.$route.params.id);
    },
    next() {
      if (this.currentQuestionId < this.currentSystemQuestions.length - 1) {
        return "Next Question";
      }

      if (this.currentSystem !== this.room.systems.length - 1) {
        return "Next System";
      }

      let roomIndex = this.rooms.findIndex((r) => r.id === this.roomId);

      if (roomIndex !== this.rooms.length - 1) {
        return "Next Room";
      }

      return "Go To Cart";
    },
    back() {
      if (this.currentQuestionId > 0) {
        return "Previous Question";
      } else if (this.currentQuestionId === 0 && this.currentSystem > 0) {
        return "Previous System";
      } else {
        return "Return to Dashboard";
      }
    },
    systemTutorial() {
      const category = this.room.systems[this.currentSystem]?.category;

      if (category)
        return this.$store.state.dashboard.systemTutorials[category];
      else return null;
    },
    showTutorial() {
      return !this.systemTutorial?.shown;
    },
    rooms: function () {
      return this.$store.state.dashboard.rooms.filter(
        (r) => r.name !== "Project"
      );
    },
  },
  watch: {
    roomId() {
      this.room = this.$store.state.dashboard.rooms.find(
        (r) => r.id === (this.roomId || 0)
      );
      this.currentSystem = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-border {
  border-top: 1px solid var(--grey);
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 600ms ease-in-out;
}
.slide-left-enter-from {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 600ms ease-in-out;
}
.slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}

.icon {
  fill: grey;
  overflow: visible;
  width: 50%;
  max-width: 70px;
}

.system-title {
  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 668px) {
    font-size: 10px;
  }

  @media (max-width: 400px) {
    font-size: 6px;
  }
}

.active {
  fill: white !important;
}

.top-bar {
  padding: 10px 0;

  border-radius: 10px;
  border: 1px solid var(--neutral-light);
  &__box {
    border-right: solid 1px grey;
    cursor: pointer;
    &:last-of-type {
      border: none;
    }
    flex-grow: 1;
    flex-basis: 0;
  }
}

.button {
  width: 120px;
  height: 40px;
  border-radius: 40px;
}

.select-box {
  padding: 10px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px white;
  &--selected {
    border: solid 2px white;
    background-color: var(--blue-1);
  }
}
</style>
