var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"p-4 p-md-5 pb-0 pb-md-0 pt-3 pt-md-3 bg-neutral-dark d-flex justify-content-between flex-column"},[(_vm.systemTutorial && _vm.systemTutorial.tutorialUrl)?_c('tutorial-pop-up',{attrs:{"title":_vm.systemTutorial.title,"show":_vm.showTutorial,"video-url":_vm.systemTutorial.tutorialUrl},on:{"close":_vm.tutorialClosed}}):_vm._e(),_c('transition',{attrs:{"mode":"out-in","name":("slide-" + _vm.transitionName)}},[_c('div',{key:_vm.roomId},[_c('div',{staticClass:"mt-2 mb-3 d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center flex-wrap"},[_c('span',{staticClass:"text-capitalize text-neutral-light strong-header-text mr-2"},[_vm._v(" "+_vm._s(_vm.$store.state.project.name || "Project Name")+" ")]),_c('v-icon',{staticClass:"d-none d-md-inline",attrs:{"color":"#fff","size":"30px"}},[_vm._v(" mdi-chevron-right ")]),_c('span',{staticClass:"d-none d-md-inline strong-header-text font-weight-300 text-primary-light"},[_vm._v(" "+_vm._s(_vm.room.name || "Project Name")+" ")])],1),_c('div',{staticClass:"d-none d-md-flex align-items-center"},[_c('div',{staticClass:"rounded-button bg-neutral-light px-3 mx-2",on:{"click":function($event){return _vm.$store.commit('dashboard/copy', _vm.roomId)}}},[_c('v-icon',{attrs:{"color":"#000","size":"18px"}},[_vm._v(" mdi-content-copy ")]),_c('span',{staticClass:"subtitle-text font-weight-500 ms-1"},[_vm._v("Copy Room Settings")])],1),_c('div',{staticClass:"rounded-button bg-dark-grey px-3 mx-2 text-neutral-dark",class:{
              'bg-neutral-light': _vm.room.systems
                .map(function (s) { return s.questions; })
                .flat()
                .some(function (q) { return q.answer_id; }),
            },on:{"click":function($event){return _vm.$store.dispatch('dashboard/resetRoom', _vm.roomId)}}},[_c('v-icon',{attrs:{"color":"#000","size":"18px"}},[_vm._v(" mdi-restart ")]),_c('span',{staticClass:"subtitle-text font-weight-500 ms-1"},[_vm._v("Reset Room Settings")])],1),_c('div',{staticClass:"rounded-button bg-dark-grey px-3 mx-2 text-neutral-dark",class:{ 'bg-neutral-light': _vm.$store.state.dashboard.clipboard },on:{"click":function($event){return _vm.$store.commit('dashboard/apply', _vm.roomId)}}},[_c('v-icon',{attrs:{"color":"#000","size":"18px"}},[_vm._v(" mdi-content-save-edit ")]),_c('span',{staticClass:"subtitle-text font-weight-500 ms-1"},[_vm._v("Apply Room Settings")])],1)])]),_c('room-picker',{staticClass:"d-block d-md-none"}),_c('section',{staticClass:"mt-3 top-bar bg-blue-3 d-flex justify-content-around align-items-center mb-3"},_vm._l((_vm.room.systems.filter(
            function (s) { return s.name !== 'Home'; }
          )),function(system,index){return _c('div',{key:system.id,staticClass:"top-bar__box d-flex flex-column justify-content-around align-items-center",on:{"click":function($event){return _vm.setCurrentSystem(index)}}},[_c('div',{staticClass:"w-100 d-flex flex-column justify-content-around align-items-center"},[_c('img',{class:['icon', _vm.currentSystem === index ? '' : 'disable'],attrs:{"src":require(("../assets/icons/Dashboard/" + (system.icon) + ".png"))}}),_c('span',{class:[
                'd-none d-md-block body-text text-center font-weight-500  mt-2 system-title text-white',
                _vm.currentSystem === index ? '' : 'disable' ]},[_vm._v(" "+_vm._s(system.name))])])])}),0),_c('transition',{attrs:{"mode":"out-in","name":("slide-" + _vm.transitionName)}},[_c('div',{key:_vm.currentSystemQuestions[0].id,staticClass:"d-flex w-100 gap-5 h-100 align-items-start mt-5"},[_c('div',{staticClass:"d-flex flex-column wd-65p z-200"},[_c('img',{staticClass:"w-100",staticStyle:{"object-fit":"contain","border-radius":"1rem","border":"1px solid #fff"},attrs:{"src":require('../assets/images/compound-bg.jpeg')}}),(_vm.currentSystemQuestions.length > 0)?_c('stepper',{staticClass:"mt-3 ms-0 w-100",attrs:{"total":_vm.currentSystemQuestions.length,"current":_vm.currentQuestionId}}):_vm._e()],1),_c('transition',{attrs:{"mode":"out-in","name":("slide-" + _vm.transitionName)}},[_c('section',{key:_vm.currentSystemQuestion,staticClass:"d-flex flex-column flex-md-row flex-wrap w-100 justify-content-center align-content-center gap-5"},[_c('room-question',{attrs:{"question":_vm.currentSystemQuestion},on:{"rating-clicked":function (questionId, OptionId) { return _vm.ratingClicked(questionId, OptionId); }}})],1)])],1)])],1)]),_c('section',{staticClass:"navigation-border bg-neutral-dark py-3 mt-5 position-sticky bottom-0"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('button',{class:[
          'border-neutral-light nav-button subtitle-text text-neutral d-flex align-items-center justify-content-center bg-black font-weight-300' ],on:{"click":_vm.prev}},[_c('v-icon',{staticClass:"me-1",attrs:{"color":"#fff","size":"20px"}},[_vm._v(" mdi-chevron-left ")]),_vm._v(" "+_vm._s(_vm.back)+" ")],1),_c('button',{staticClass:"nav-button text-uppercase subtitle-text text-neutral d-flex align-items-center justify-content-center bg-blue-2 font-weight-300",on:{"click":_vm.nextPressed}},[_vm._v(" "+_vm._s(_vm.next)+" "),_c('v-icon',{staticClass:"me-1",attrs:{"color":"#fff","size":"20px"}},[_vm._v(" mdi-chevron-right ")])],1)])]),(_vm.showLoading)?_c('cart-loading',{on:{"loading-done":_vm.cartLoadingDone}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }