<template>
  <div class="question">
    <div class="text-muted-light strong-header-text font-weight-300 mb-2">
      {{ question.text }}
    </div>
    <div
      v-if="question.question_type === 'radio'"
      class="d-flex align-items-center"
    >
      <v-radio-group v-model="question.answer_id" row>
        <v-radio
          v-for="option in question.answer_options"
          color="#fff"
          class="me-4"
          dark
          :key="option.text"
          :value="option.id"
        >
          <template #label>
            <span class="ms-1 text-muted-light body-text">
              {{ option.text }}
            </span>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
    <div
      v-if="question.question_type === 'rating'"
      class="d-flex align-items-center justify-content-between justify-content-md-start gap-3"
    >
      <div
        v-for="option in question.answer_options"
        :key="option.id"
        :class="[
          'rating-box me-1 text-light cursor-pointer',
          question.answer_id === option.id ? 'rating-box--selected' : '',
        ]"
        @click="$emit('rating-clicked', question.id, option.id)"
      >
        {{ option.text }}
      </div>
    </div>
    <select-question
      class="ms-2"
      width="col-3"
      v-if="question.question_type === 'image_select'"
      :options="getOptions(question.answer_options)"
      v-model="question.answer_id"
    />
  </div>
</template>

<script>
import SelectQuestion from "../components/SelectQuestion.vue";

export default {
  name: "RoomQuestion",
  components: { SelectQuestion },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getOptions(options) {
      return options.map((option) => {
        return {
          ...option,
          image: require(`/src/assets/icons/Dashboard/Speakers.png`),
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  width: 100%;
  margin-bottom: 20px;
}

.rating-box {
  width: 50px;
  height: 40px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px white;
  &--selected {
    border: solid 2px white;
    background-color: var(--blue-1);
  }
}
</style>
